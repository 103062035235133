import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LocalizedDatePipe } from './localized-date.pipe';
import { LocalizedTimePipe } from './localized-time.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [LocalizedDatePipe, LocalizedTimePipe],
  exports: [LocalizedDatePipe, LocalizedTimePipe],
  providers: [LocalizedDatePipe, LocalizedTimePipe],
})
export class SharedPipesModule {}
