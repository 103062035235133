import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import {
  BtnColors,
  BtnHierarchies,
  BtnSizes,
  DateHelperService,
  IconTypes,
  MessagingService,
} from 'shared';
import { UpdateTimeMessage } from 'src/app/models/messages/update-time-message.model';
import { CalendarEventService } from '../../_services';
import { CalendarDay, State } from '../../models/calendar-event.model';

@Component({
  selector: 'app-calendar-event-calendar',
  templateUrl: './calendar-event-calendar.component.html',
  styleUrls: ['./calendar-event-calendar.component.scss'],
})
export class CalendarEventCalendarComponent {
  public state$: Observable<State> = this.service.state$;

  public enums = {
    BtnColors,
    BtnSizes,
    BtnHierarchies,
    IconTypes,
  };

  constructor(
    public service: CalendarEventService,
    private _messagingService: MessagingService,
    private _dateService: DateHelperService
  ) {}

  nextMonth() {
    this.service.nextMonth();
  }
  previousMonth() {
    this.service.previousMonth();
  }

  onDayClick(day: CalendarDay) {
    this.service.updateSelectedDate(day);
  }
  onTimeClick(time: Date) {
    return () =>
      this._messagingService.publish(
        new UpdateTimeMessage(
          this._dateService.convertDateToNgbTimeStruct(time)
        )
      );
  }
}
