import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { Observable, map, of } from 'rxjs';
import { Lang, TranslateService } from 'shared';
import { DateDisplayType } from '../api/models';

@Pipe({
  name: 'localizedTime',
})
export class LocalizedTimePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    value: Date | string | NgbTimeStruct,
    dateDisplayType?: DateDisplayType
  ): Observable<string> {
    if (
      typeof value === 'object' &&
      !(value instanceof Date) &&
      value.hour !== undefined
    ) {
      // convert NgbTimeStruct into Date
      value = new Date(0, 0, 1, value.hour, value.minute);
    }

    if (dateDisplayType) {
      return of(this.formatTime(value as Date | string, dateDisplayType));
    }

    return this.translateService.currentLang$.pipe(
      map((lang) => this.formatTime(value as Date | string, dateDisplayType))
    );
  }

  private formatTime(
    value: Date | string,
    dateDisplayType: DateDisplayType
  ): string {
    const format = dateDisplayType == DateDisplayType.US ? 'h:mm a' : 'HH:mm';
    const locale = dateDisplayType == DateDisplayType.US ? Lang.en : Lang.fr;

    return new DatePipe(locale).transform(value, format);
  }
}
