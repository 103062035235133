/* tslint:disable */
type CandidateTargetField =
  'FirstName' |
  'LastName' |
  'Phone' |
  'Email' |
  'PhotoUrl' |
  'Civility' |
  'Linkedin' |
  'Github' |
  'SalaryExpectation' |
  'Availability';
module CandidateTargetField {
  export const FIRST_NAME: CandidateTargetField = 'FirstName';
  export const LAST_NAME: CandidateTargetField = 'LastName';
  export const PHONE: CandidateTargetField = 'Phone';
  export const EMAIL: CandidateTargetField = 'Email';
  export const PHOTO_URL: CandidateTargetField = 'PhotoUrl';
  export const CIVILITY: CandidateTargetField = 'Civility';
  export const LINKEDIN: CandidateTargetField = 'Linkedin';
  export const GITHUB: CandidateTargetField = 'Github';
  export const SALARY_EXPECTATION: CandidateTargetField = 'SalaryExpectation';
  export const AVAILABILITY: CandidateTargetField = 'Availability';
  export function values(): CandidateTargetField[] {
    return [
      FIRST_NAME,
      LAST_NAME,
      PHONE,
      EMAIL,
      PHOTO_URL,
      CIVILITY,
      LINKEDIN,
      GITHUB,
      SALARY_EXPECTATION,
      AVAILABILITY
    ];
  }
}

export { CandidateTargetField }