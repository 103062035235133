import {
  CommonModule,
  DatePipe,
  TitleCasePipe,
  registerLocaleData,
} from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import { Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {
  AvatarModule,
  BadgeModule,
  BtnModule,
  ContainerModule,
  IconModule,
  InlineMsgModule,
  InputModule,
  LoaderDirective,
  MultiSelectModule,
  SegmentedControlModule,
  SelectModule,
  TextAreaModule,
} from 'core-components';
import {
  ApiTranslationsModule,
  InjectorService,
  InternationalizationModule,
  SharedPipesModule,
  SizeDetectorModule,
  TranslatePipe,
} from 'shared';
import { environment } from 'src/environments/environment';
import { SharedPipesModule as BookingSharedPipesModule } from './_pipes';
import { CalendarEventService } from './_services';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CalendarEventCalendarComponent } from './calendar-event/calendar-event-calendar/calendar-event-calendar.component';
import { CalendarEventComponent } from './calendar-event/calendar-event.component';
import { CandidateFormComponent } from './calendar-event/candidate-form/candidate-form.component';
import { StepperComponent } from './calendar-event/stepper/stepper.component';
import { CancelAppointmentComponent } from './cancel-appointment/cancel-appointment.component';
import { CancelConfirmationComponent } from './cancel-confirmation/cancel-confirmation.component';
import { BookingErrorInterceptor } from './interceptors/booking-error-interceptor';
import { NotFoundComponent } from './not-found/not-found.component';
import { SuccessComponent } from './success/success.component';

@NgModule({
  declarations: [
    AppComponent,
    CalendarEventComponent,
    NotFoundComponent,
    CalendarEventCalendarComponent,
    CandidateFormComponent,
    StepperComponent,
    SuccessComponent,
    CancelAppointmentComponent,
    CancelConfirmationComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    ApiTranslationsModule.forRoot({ rootUrl: environment.apiUrl }),
    HttpClientModule,
    FormsModule,
    CommonModule,
    BrowserAnimationsModule,
    InternationalizationModule,
    ReactiveFormsModule,
    BtnModule,
    InputModule,
    SelectModule,
    ContainerModule,
    MultiSelectModule,
    InlineMsgModule,
    LoaderDirective,
    SharedPipesModule,
    BookingSharedPipesModule,
    SegmentedControlModule,
    TextAreaModule,
    AppRoutingModule,
    ApiModule.forRoot({ rootUrl: environment.apiUrl }),
    NgbModule,
    IconModule,
    AvatarModule,
    BadgeModule,
    SizeDetectorModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useFactory: ErrorInterceptorFactory,
      deps: [Injector, Router],
      multi: true,
    },
    { provide: LOCALE_ID, useValue: navigator.language ?? 'fr' },
    DatePipe,
    TranslatePipe,
    TitleCasePipe,
    CalendarEventService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    InjectorService.injector = injector;
    registerLocaleData(localeFr, 'fr');
    registerLocaleData(localeEn, 'en');
  }
}

function ErrorInterceptorFactory(injector: Injector, router: Router) {
  const errorInterceptor = new BookingErrorInterceptor(injector, router);
  return errorInterceptor;
}
