import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ErrorDto } from '../models/error-dto';

@Injectable({
  providedIn: 'root',
})
export class ErrorsService {
  private _errorSubject = new BehaviorSubject<ErrorDto | null>(null);
  public error$ = this._errorSubject.asObservable();

  setError(error: ErrorDto) {
    this._errorSubject.next(error);
  }

  clearError() {
    this._errorSubject.next(null);
  }
}
