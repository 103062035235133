<ng-container *ngIf="state$ | async as state">
  <wr-container [btnList]="state.btnList" [formGroup]="state.form">
    <div class="container">
      <div class="row">
        <ng-container formArrayName="questions">
          <ng-container
            *ngFor="
              let question of state.form.controls.questions.controls;
              let index = index
            "
            [formGroupName]="index"
          >
            <ng-container
              *ngIf="
                question.value.type === enums.QuestionType.YES_NO ||
                question.value.candidateTargetField ===
                  enums.CandidateTargetField.CIVILITY
              "
            >
              <div class="col-12 m--b--5 p--x--5">
                <wr-segmented-control
                  [label]="question.value.name"
                  formControlName="value"
                  [required]="question.value.required"
                  [errors]="errors"
                  [optional]="!question.value.required"
                  [items]="question.value.choices"
                ></wr-segmented-control>
              </div>
            </ng-container>
            <ng-container
              *ngIf="question.value.type === enums.QuestionType.TEXT"
            >
              <div class="col-12 col-lg-6 m--b--5 p--x--5">
                <wr-input
                  [label]="question.value.name"
                  [placeholder]="question.value.name"
                  formControlName="value"
                  [required]="question.value.required"
                  [errors]="errors"
                  [optional]="!question.value.required"
                  [type]="
                    question.value.candidateTargetField ===
                    enums.CandidateTargetField.EMAIL
                      ? enums.InputComponentType.EMAIL
                      : enums.InputComponentType.TEXT
                  "
                ></wr-input>
              </div>
            </ng-container>
            <ng-container
              *ngIf="question.value.type === enums.QuestionType.NUMBER"
            >
              <div class="col-12 col-lg-6 m--b--5 p--x--5">
                <wr-input
                  [label]="question.value.name"
                  [placeholder]="question.value.name"
                  formControlName="value"
                  [required]="question.value.required"
                  [errors]="errors"
                  [optional]="!question.value.required"
                  [type]="enums.InputComponentType.NUMBER"
                ></wr-input>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                (question.value.type === enums.QuestionType.QCU ||
                  question.value.type === enums.QuestionType.LANGUAGE) &&
                question.value.candidateTargetField !==
                  enums.CandidateTargetField.CIVILITY
              "
            >
              <div class="col-12 col-lg-6 m--b--5 p--x--5">
                <wr-select
                  [label]="question.value.name"
                  [placeholder]="question.value.name"
                  formControlName="value"
                  [items]="question.value.choices"
                  [required]="question.value.required"
                  [errors]="errors"
                  [optional]="!question.value.required"
                >
                </wr-select>
              </div>
            </ng-container>
            <ng-container
              *ngIf="
                question.value.type === enums.QuestionType.QCM ||
                question.value.type === enums.QuestionType.QCMTAG
              "
            >
              <div class="col-12 col-lg-6 m--b--5 p--x--5">
                <wr-multi-select
                  [label]="question.value.name"
                  formControlName="value"
                  [ngModel]="question.value.choices"
                  [placeholder]="question.value.name"
                  [optional]="!question.value.required"
                  [required]="question.value.required"
                  [errors]="errors"
                >
                </wr-multi-select>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </wr-container>
</ng-container>
