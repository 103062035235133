/* tslint:disable */
type QuestionTargetFieldType =
  'none' |
  'post' |
  'candidate';
module QuestionTargetFieldType {
  export const NONE: QuestionTargetFieldType = 'none';
  export const POST: QuestionTargetFieldType = 'post';
  export const CANDIDATE: QuestionTargetFieldType = 'candidate';
  export function values(): QuestionTargetFieldType[] {
    return [
      NONE,
      POST,
      CANDIDATE
    ];
  }
}

export { QuestionTargetFieldType }