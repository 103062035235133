<div class="confirmation">
  <div class="row confirmation__logo-container">
    <div
      class="col-12 align-content-center confirmation__logo-wrap"
      *ngIf="state$ | async as state"
    >
      <img [src]="state.informations.companyLogoUrl" />
    </div>
  </div>
  <div class="confirmation__content__wrapper">
    <img
      class="confirmation__content__graphic ellipsis"
      alt="forme graphique"
      src="https://wio.blob.core.windows.net/cdn/app/images/graphic-resources/ellipse-login.png"
    />
    <img
      class="confirmation__content__graphic cloud"
      alt="forme graphique"
      src="https://wio.blob.core.windows.net/cdn/app/images/graphic-resources/nuage1.svg"
    />

    <div class="confirmation__content">
      <div class="row justify-content-center confirmation__body-container">
        <div class="col confirmation__body">
          <i
            class="calendar m--b--5"
            [ngClass]="iconService.types.CalendarTimes"
          ></i>

          <p class="title m--b--5">
            {{ "Calendar.blkCancel.confirmation" | translate }}
          </p>
          <ng-container *ngIf="message$ | async as message">
            <p class="message m--b--5">
              {{ message }}
            </p>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
