import { Component } from '@angular/core';
import { BadgeService } from 'core-components';
import { takeUntil, tap } from 'rxjs/operators';
import { IconService, WithUnsubscribe } from 'shared';
import { CalendarEventService } from '../../_services';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent extends WithUnsubscribe() {
  public state$ = this._service.state$;

  steps: Array<any> = [];

  constructor(
    private readonly _service: CalendarEventService,
    public readonly badgeService: BadgeService,
    public readonly iconService: IconService
  ) {
    super();
  }

  ngOnInit(): void {
    this.state$
      .pipe(
        takeUntil(this.unsubscribe$),
        tap((state) => this.manageSteps(state))
      )
      .subscribe();
  }

  manageSteps(state) {
    switch (state.currentStep) {
      case 0:
        this.steps[0] = {
          badgeColor: this.badgeService.colors.Primary,
          icon: {
            type: this.iconService.types.ArrowRight,
            color: this.iconService.colors.Primary,
          },
        };

        this.steps[1] = {
          badgeColor: this.badgeService.colors.Ghost,
          icon: {
            type: this.iconService.types.Lock,
            color: this.iconService.colors.Neutral,
            size: this.iconService.sizes.MD,
          },
        };
        break;
      case 1:
        this.steps[0] = {
          badgeColor: this.badgeService.colors.Success,
          icon: {
            type: this.iconService.types.Check,
            color: this.iconService.colors.Success,
          },
        };
        this.steps[1] = {
          badgeColor: this.badgeService.colors.Primary,
          icon: {
            type: this.iconService.types.ArrowRight,
            color: this.iconService.colors.Primary,
            size: this.iconService.sizes.XL,
          },
        };
        break;
    }
  }
}
