/* tslint:disable */
type AvailabilityDay =
  'None' |
  'Monday' |
  'Tuesday' |
  'Wednesday' |
  'Thursday' |
  'Friday' |
  'Weekdays' |
  'Saturday' |
  'Sunday' |
  'Weekends' |
  'AllDays';
module AvailabilityDay {
  export const NONE: AvailabilityDay = 'None';
  export const MONDAY: AvailabilityDay = 'Monday';
  export const TUESDAY: AvailabilityDay = 'Tuesday';
  export const WEDNESDAY: AvailabilityDay = 'Wednesday';
  export const THURSDAY: AvailabilityDay = 'Thursday';
  export const FRIDAY: AvailabilityDay = 'Friday';
  export const WEEKDAYS: AvailabilityDay = 'Weekdays';
  export const SATURDAY: AvailabilityDay = 'Saturday';
  export const SUNDAY: AvailabilityDay = 'Sunday';
  export const WEEKENDS: AvailabilityDay = 'Weekends';
  export const ALL_DAYS: AvailabilityDay = 'AllDays';
  export function values(): AvailabilityDay[] {
    return [
      NONE,
      MONDAY,
      TUESDAY,
      WEDNESDAY,
      THURSDAY,
      FRIDAY,
      WEEKDAYS,
      SATURDAY,
      SUNDAY,
      WEEKENDS,
      ALL_DAYS
    ];
  }
}

export { AvailabilityDay }