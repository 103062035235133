/* tslint:disable */
type DateDisplayType =
  'French' |
  'US';
module DateDisplayType {
  export const FRENCH: DateDisplayType = 'French';
  export const US: DateDisplayType = 'US';
  export function values(): DateDisplayType[] {
    return [
      FRENCH,
      US
    ];
  }
}

export { DateDisplayType }