import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InlineMsgThemes } from 'core-components';
import { combineLatest, map, of } from 'rxjs';
import { IconService, TranslateService } from 'shared';
import { Observable } from '../../../node_modules/rxjs/dist/types/internal/Observable';
import { CalendarEventService } from '../_services';
import { CreateNewAppointmentResponse } from '../api/models';

@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  public state$ = this.calendarEventService.state$;
  public customMessage$ = this.state$.pipe(
    map((state) => state.informations.candidateCustomMessage)
  );
  public message$ = combineLatest([
    this.state$,
    this.translateService.currentLang$,
    this.getNewAppointment$(),
  ]).pipe(
    map(([state, lang, newAppointmentResult]) => {
      if (!newAppointmentResult) {
        return '';
      }

      const args = {
        user: state.informations.userName,
        date: newAppointmentResult.appointmentFormattedDate,
        hour: newAppointmentResult.appointmentFormattedTime,
      };
      return this.translateService.getTranslationValue(
        'Calendar.blkSuccess.message',
        lang,
        args
      );
    })
  );
  enums = {
    InlineMsgThemes,
  };
  constructor(
    private readonly calendarEventService: CalendarEventService,
    public readonly iconService: IconService,
    private readonly translateService: TranslateService,
    private readonly _activatedRoute: ActivatedRoute
  ) {}
  ngOnInit(): void {
    this.calendarEventService.updateRouteParams(this._activatedRoute);
  }

  private getNewAppointment$(): Observable<CreateNewAppointmentResponse> {
    return this._activatedRoute.paramMap.pipe((params) => {
      if (window.history.state && window.history.state.newAppointment) {
        return of(window.history.state.newAppointment);
      }
      return of(null);
    });
  }
}
