import { Component, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { TranslateService, WithUnsubscribe } from 'shared';
import { ErrorsService } from '../_services';
import { ErrorDto } from '../models/error-dto';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent extends WithUnsubscribe() implements OnInit {
  error: ErrorDto;

  constructor(
    private readonly _errorsService: ErrorsService,
    private readonly _translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    this._errorsService.error$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((error) => {
        this.error = error;
      });
  }

  getErrorMessage(): string {
    if (this.error?.status === 500) {
      return this.getDefaultErrorMessage();
    }
    if (this.error?.message) {
      return this.error?.message;
    }
    if (this.error?.code) {
      return this.error?.code;
    }
    return this.getDefaultErrorMessage();
  }

  private getDefaultErrorMessage(): string {
    return this._translateService.getTranslationValue(
      'global.lblAnErrorOccured'
    );
  }
}
