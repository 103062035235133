import { NgModule, inject } from '@angular/core';
import {
  CanMatchFn,
  Route,
  RouterModule,
  Routes,
  UrlSegment,
} from '@angular/router';
import { TranslateService, filter } from 'shared';
import { CalendarEventComponent } from './calendar-event/calendar-event.component';
import { CancelAppointmentComponent } from './cancel-appointment/cancel-appointment.component';
import { CancelConfirmationComponent } from './cancel-confirmation/cancel-confirmation.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { SuccessComponent } from './success/success.component';

const canMatchTranslations: CanMatchFn = (
  route: Route,
  segments: UrlSegment[]
) => {
  return inject(TranslateService).translationsLoadedSubject.pipe(filter());
};

export const appRoutes: Routes = [
  {
    path: 'cancel/:eventId',
    component: CancelAppointmentComponent,
    canMatch: [canMatchTranslations],
  },
  {
    path: ':userInfo/:eventSlug',
    component: CalendarEventComponent,
    canMatch: [canMatchTranslations],
  },
  {
    path: ':userInfo/:eventSlug/:activityLink',
    component: CalendarEventComponent,
    canMatch: [canMatchTranslations],
  },
  {
    path: 'success',
    component: SuccessComponent,
    canMatch: [canMatchTranslations],
  },

  {
    path: 'cancel-confirmation',
    component: CancelConfirmationComponent,
    canMatch: [canMatchTranslations],
  },
  {
    path: '**',
    component: NotFoundComponent,
    canMatch: [canMatchTranslations],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
