import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { Lang, TranslateService } from 'shared';
import { DateDisplayType } from '../api/models';

@Pipe({
  name: 'localizedDate',
})
export class LocalizedDatePipe implements PipeTransform {
  constructor(private translateService: TranslateService) {}

  transform(
    value: Date | string,
    format = 'mediumDate',
    dateDisplayType?: DateDisplayType
  ): Observable<string> {
    const locale = dateDisplayType === DateDisplayType.US ? Lang.en : Lang.fr;

    if (dateDisplayType) {
      return of(new DatePipe(locale).transform(value, format));
    }

    return this.translateService.currentLang$.pipe(
      map((lang) => new DatePipe(locale).transform(value, format))
    );
  }
}
