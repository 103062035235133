/* tslint:disable */
type QuestionType =
  'Text' |
  'YesNo' |
  'QCM' |
  'QCMTag' |
  'Date' |
  'Address' |
  'Language' |
  'Number' |
  'QCU';
module QuestionType {
  export const TEXT: QuestionType = 'Text';
  export const YES_NO: QuestionType = 'YesNo';
  export const QCM: QuestionType = 'QCM';
  export const QCMTAG: QuestionType = 'QCMTag';
  export const DATE: QuestionType = 'Date';
  export const ADDRESS: QuestionType = 'Address';
  export const LANGUAGE: QuestionType = 'Language';
  export const NUMBER: QuestionType = 'Number';
  export const QCU: QuestionType = 'QCU';
  export function values(): QuestionType[] {
    return [
      TEXT,
      YES_NO,
      QCM,
      QCMTAG,
      DATE,
      ADDRESS,
      LANGUAGE,
      NUMBER,
      QCU
    ];
  }
}

export { QuestionType }