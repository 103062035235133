<ng-container *ngIf="state$ | async as state">
  <div class="row calendar-event__logo-container">
    <div class="col-12 align-content-center calendar-event__logo-wrap">
      <img [src]="state.informations.companyLogoUrl" />
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-12 col-md-8 col-lg-6">
      <div class="calendar-event__cancel-informations">
        <div class="calendar-event__cancel-informations__header">
          <wr-icon
            [type]="iconService.types.InfoCircle"
            [shade]="iconService.shades.Shade10"
            [size]="iconService.sizes.MD"
            class="m--r--3"
          ></wr-icon
          >{{ "Calendar.blkCancel.title" | translate }}
        </div>
        <div class="calendar-event__cancel-informations__body">
          <div class="calendar-event__cancel-informations__body__informations">
            <div
              class="calendar-event__cancel-informations__body__informations__user"
            >
              <div
                class="calendar-event__informations__user-avatar calendar-event__cancel-informations__body__informations__user-avatar"
              >
                <div
                  class="calendar-event__informations__user-avatar--wrap calendar-event__cancel-informations__body__informations__user-avatar--wrap"
                >
                  <wr-avatar
                    [size]="enums.AvatarSizes.XXL"
                    [url]="state.informations.appointedUserAvatarUrl"
                  >
                  </wr-avatar>
                </div>
              </div>
              <p
                class="calendar-event__cancel-informations__body__informations__user-name"
              >
                {{ state.informations.appointedUserFullName }}
              </p>
            </div>

            <div
              class="calendar-event__cancel-informations__body__informations__container"
            >
              <p
                class="calendar-event__cancel-informations__body__informations__title"
              >
                {{ state.informations.title }}
              </p>
              <p
                class="calendar-event__cancel-informations__body__informations__date"
              >
                <wr-icon
                  [type]="iconService.types.Calendar"
                  [color]="iconService.colors.Primary"
                  [shade]="iconService.shades.Shade2"
                  class="m--r--3"
                ></wr-icon>
                {{ state.informations.appointmentFormattedDate }}
                -
                <span
                  class="calendar-event__cancel-informations__body__informations__date__hour"
                  >{{ state.informations.appointmentFormattedTime }}</span
                >
              </p>
              <p
                class="calendar-event__cancel-informations__body__informations__duration"
              >
                <wr-icon
                  [type]="iconService.types.Delay"
                  [color]="iconService.colors.Primary"
                  [shade]="iconService.shades.Shade2"
                  class="m--r--3"
                ></wr-icon>

                {{ state.informations.duration }}
              </p>
              <p
                class="calendar-event__cancel-informations__body__informations__address"
                *ngIf="state.informations.address?.length > 0"
              >
                <wr-icon
                  [type]="iconService.types.MapMarkerAlt"
                  [color]="iconService.colors.Primary"
                  [shade]="iconService.shades.Shade2"
                  class="m--r--3"
                ></wr-icon>

                {{ state.informations.address }}
              </p>
              <p
                class="calendar-event__cancel-informations__body__informations__phone"
                *ngIf="state.informations.phoneNumber?.length > 0"
              >
                <wr-icon
                  [type]="iconService.types.Phone"
                  [color]="iconService.colors.Primary"
                  [shade]="iconService.shades.Shade2"
                  class="m--r--3"
                ></wr-icon>
                {{ state.informations.phoneNumber }}
              </p>
              <p
                class="calendar-event__cancel-informations__body__informations__video-url"
                *ngIf="state.informations.visioUrl"
              >
                <wr-icon
                  [type]="iconService.types.Video"
                  [color]="iconService.colors.Primary"
                  [shade]="iconService.shades.Shade2"
                  class="m--r--3"
                ></wr-icon>
                <a href="{{ state.informations.visioUrl }}">
                  {{ state.informations.visioUrl }}
                </a>
              </p>
            </div>
          </div>
          <div class="row" [formGroup]="state.form">
            <wr-text-area
              class="m--t--7 m--b--9"
              formControlName="candidateMessage"
              [placeholder]="'Calendar.candidateMessage.plh' | translate"
            >
            </wr-text-area>
          </div>
          <div class="calendar-event__btn-row">
            <wr-btn
              [color]="enums.BtnColors.Danger"
              [theme]="enums.BtnThemes.Default"
              [hierarchy]="enums.BtnHierarchies.Primary"
              [action$]="cancelAppointment$()"
            >
              {{ "Calendar.blkCancel.btnCancelAppointment" | translate }}
            </wr-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>
