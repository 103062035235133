<ng-container *ngIf="state$ | async as state">
  <div
    class="row justify-content-center calendar-event__stepper__container d-none d-sm-flex"
  >
    <div class="col-auto calendar-event__stepper">
      <div class="calendar-event__stepper__header">
        <wr-icon
          [type]="steps[0].icon.type"
          [color]="steps[0].icon.color"
          [size]="iconService.sizes.LG"
          class="calendar-event__stepper__header__icon"
        ></wr-icon>

        <span
          class="calendar-event__stepper__header__line"
          [ngClass]="
            state.currentStep === 0
              ? 'current'
              : state.currentStep > 0
              ? 'done'
              : ''
          "
        ></span>
      </div>
      <div class="calendar-event__stepper__content">
        {{ "Calendar.blkStepper.lblStep" | translate }} 1

        <wr-badge
          [label]="'Calendar.blkStepper.titleStep1' | translate"
          [size]="badgeService.sizes.LG"
          [color]="steps[0].badgeColor"
          class="d-block"
        ></wr-badge>
      </div>
    </div>

    <div class="col-auto calendar-event__stepper">
      <div class="calendar-event__stepper__header">
        <wr-icon
          [type]="steps[1].icon.type"
          [color]="steps[1].icon.color"
          [size]="steps[1].icon.size"
          class="calendar-event__stepper__header__icon"
        ></wr-icon>

        <span
          class="calendar-event__stepper__header__line"
          [ngClass]="
            state.currentStep === 1
              ? 'current'
              : state.currentStep > 1
              ? 'done'
              : ''
          "
        ></span>
      </div>
      <div class="calendar-event__stepper__content">
        {{ "Calendar.blkStepper.lblStep" | translate }} 2

        <wr-badge
          [label]="'Calendar.blkStepper.titleStep2' | translate"
          [size]="badgeService.sizes.LG"
          [color]="steps[1].badgeColor"
          class="d-block"
        ></wr-badge>
      </div>
    </div>

    <div class="col-auto calendar-event__stepper">
      <div class="calendar-event__stepper__header">
        <wr-icon
          [type]="iconService.types.Trophy"
          [color]="iconService.colors.Neutral"
          [size]="iconService.sizes.MD"
          class="calendar-event__stepper__header__icon"
        ></wr-icon>
      </div>
    </div>
  </div>
</ng-container>
