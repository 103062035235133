import { Component, OnInit } from '@angular/core';
import { CancelAppointmentService } from './cancel-appointment.service';
import { ActivatedRoute } from '@angular/router';
import { shareReplay, switchMap } from 'rxjs';
import {
  BtnColors,
  BtnThemes,
  IconService,
  BtnSizes,
  BtnHierarchies,
} from 'shared';
import { AvatarSizes } from 'core-components';

@Component({
  selector: 'app-cancel-appointment',
  templateUrl: './cancel-appointment.component.html',
  styleUrls: [
    './../calendar-event/calendar-event.component.scss',
    './cancel-appointment.component.scss',
  ],
})
export class CancelAppointmentComponent implements OnInit {
  public enums = {
    BtnColors,
    BtnSizes,
    BtnThemes,
    BtnHierarchies,
    AvatarSizes,
  };
  public state$ = this.service.state$.pipe(shareReplay(1));

  constructor(
    private _activatedRoute: ActivatedRoute,
    public service: CancelAppointmentService,
    public iconService: IconService
  ) {}

  ngOnInit(): void {
    this.service.updateRouteParams(this._activatedRoute);
  }
  cancelAppointment$() {
    return () =>
      this.state$.pipe(
        switchMap((state) => this.service.cancelAppointment$(state.form))
      );
  }
}
