import { Injectable } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import {
  Observable,
  ReplaySubject,
  catchError,
  combineLatest,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { DateHelperService, TranslateService, WithUnsubscribe } from 'shared';
import { LanguageHelper } from 'wr-components';
import { GetCancelBookingInformationsResponse } from '../api/models';
import { CalendarEventsService } from '../api/services';
import {
  CancelAppointmentFromGroup as CancelAppointmentFormGroup,
  State,
} from './cancel-appointment.model';

@Injectable({
  providedIn: 'root',
})
export class CancelAppointmentService extends WithUnsubscribe() {
  private _params$: ReplaySubject<ParamMap> = new ReplaySubject(1);
  private _informations$: Observable<GetCancelBookingInformationsResponse> =
    this.getInformations$().pipe(shareReplay(1));
  private _form$: Observable<CancelAppointmentFormGroup> = this.getForm$().pipe(
    shareReplay(1)
  );
  private eventId: string;
  public state$: Observable<State> = this.getState$();

  constructor(
    private readonly _calendarEventsService: CalendarEventsService,
    private readonly _fb: FormBuilder,
    private readonly _languageHelper: LanguageHelper,
    private readonly _router: Router,
    private readonly _translateService: TranslateService
  ) {
    super();
  }

  private getForm$(): Observable<CancelAppointmentFormGroup> {
    return of(this._fb.group({ candidateMessage: '' }));
  }

  public updateRouteParams(route: ActivatedRoute) {
    route.paramMap.subscribe((params) => this._params$.next(params));
  }

  public cancelAppointment$(form: CancelAppointmentFormGroup) {
    const args: CalendarEventsService.CancelAppointmentParams = {
      calendarEventId: this.eventId,
      body: {
        candidateMessage: form.value.candidateMessage,
      },
    };
    return this._calendarEventsService.CancelAppointment(args).pipe(
      tap((result) => {
        if (result.success) {
          this._router.navigate(['cancel-confirmation']);
        }
      })
    );
  }

  private getInformations$(): Observable<GetCancelBookingInformationsResponse> {
    return this._params$.pipe(
      switchMap((params) => {
        this.eventId = params.get('eventId');
        return this._calendarEventsService.GetCancelBookingInformations(
          this.eventId
        );
      }),
      tap((informations) => {
        const language = this._languageHelper.convertToLang(
          informations.language
        );

        if (language) {
          this._translateService.setCurrentLang(language);
        }
      }),
      catchError((_) => {
        this._router.navigate(['']);
        return of(null);
      })
    );
  }

  private getState$(): Observable<State> {
    return combineLatest([this._informations$, this._form$]).pipe(
      map(([informations, form]) => ({
        informations,
        form,
      }))
    );
  }
}
