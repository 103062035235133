import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AvatarSizes } from 'core-components';
import { shareReplay } from 'rxjs';
import { IconService } from 'shared';
import { CalendarEventService } from '../_services';

@Component({
  selector: 'app-calendar-event',
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss'],
})
export class CalendarEventComponent implements OnInit {
  public state$ = this.service.state$.pipe(shareReplay(1));

  public enums = {
    AvatarSizes,
  };

  constructor(
    public readonly service: CalendarEventService,
    private readonly _activatedRoute: ActivatedRoute,
    public readonly iconService: IconService
  ) {}
  ngOnInit(): void {
    this.service.updateRouteParams(this._activatedRoute);
  }
}
