import { Component } from '@angular/core';
import { IndexedDbService, TranslateService } from 'shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  title = 'app';
  /**
   *
   */
  constructor(
    private _indexedDbService: IndexedDbService,
    public translationsService: TranslateService
  ) {
    this._indexedDbService.initDatabase();
    translationsService.init();
  }
}
