import { Component } from '@angular/core';
import { InlineMsgThemes } from 'core-components';
import { map } from 'rxjs';
import {
  DateHelperService,
  IconService,
  TranslateService,
  plucker,
} from 'shared';
import { CancelAppointmentService } from '../cancel-appointment/cancel-appointment.service';

@Component({
  selector: 'app-cancel-confirmation',
  templateUrl: './cancel-confirmation.component.html',
  styleUrls: [
    '../success/success.component.scss',
    './cancel-confirmation.component.scss',
  ],
})
export class CancelConfirmationComponent {
  public state$ = this.service.state$;
  public message$ = this.state$.pipe(
    plucker((s) => s.informations),
    map((informations) => {
      const args = {
        user: informations.appointedUserFullName,
        date: informations.appointmentFormattedDate,
        hour: informations.appointmentFormattedTime,
      };
      return this._translateService.getTranslationValue(
        'Calendar.blkCancelSuccess.message',
        undefined,
        args
      );
    })
  );

  enums = {
    InlineMsgThemes,
  };

  constructor(
    public service: CancelAppointmentService,
    public iconService: IconService,
    private readonly _dateService: DateHelperService,
    private readonly _translateService: TranslateService
  ) {}
}
