import { Component } from '@angular/core';
import { InputComponentType } from 'core-components';
import {
  BtnColors,
  TranslateService,
  ValidationError,
  ValidationErrorType,
} from 'shared';
import { CalendarEventService } from '../../_services';
import { CandidateTargetField, QuestionType } from '../../api/models';

@Component({
  selector: 'app-candidate-form',
  templateUrl: './candidate-form.component.html',
  styleUrls: ['./candidate-form.component.scss'],
})
export class CandidateFormComponent {
  public state$ = this._service.state$;
  public enums = {
    CandidateTargetField,
    QuestionType,
    BtnColors,
    InputComponentType,
  };
  public readonly errors: ValidationError[] = [
    {
      type: ValidationErrorType.Required,
      msg: this._translateService.getTranslationValue(
        'Calendar.blkError.lblRequired'
      ),
    },
    {
      type: ValidationErrorType.Phone,
      msg: this._translateService.getTranslationValue(
        'Calendar.blkError.lblPhoneFormat'
      ),
    },
    {
      type: ValidationErrorType.Email,
      msg: this._translateService.getTranslationValue(
        'Calendar.blkError.lblEmailFormat'
      ),
    },
  ];

  constructor(
    private readonly _service: CalendarEventService,
    private readonly _translateService: TranslateService
  ) {}
}
