import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorsService } from '../_services';
import { ErrorDto } from '../models/error-dto';

@Injectable()
export class BookingErrorInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const errorsService = this.injector.get(ErrorsService);

    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        this.handleError(err, errorsService);
        return throwError(() => err);
      })
    );
  }

  private handleError(err: HttpErrorResponse, errorsService: ErrorsService) {
    let error = err.error;
    let dto: ErrorDto = {
      status: err.status,
      path: this.normalizePath(error?.path),
    };

    if (
      error &&
      typeof error !== 'string' &&
      error?.errors &&
      error?.errors.some((x) => !!x.code)
    ) {
      dto.code = error.errors[0].code;
      dto.message = error.errors[0].message;
    }

    errorsService.setError(dto);
    this.router.navigate(['']);
  }

  private normalizePath(path: string): string {
    if (!path) {
      return '';
    }
    return path.replace('/api/calendar-events/', '/');
  }
}
