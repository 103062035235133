<div class="empty-state">
  <!-- width="170" height="190" viewBox="0 0 200 200" -->

  <div class="empty-state__svg">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      viewBox="0 0 363.86117 325"
    >
      <defs id="defs22">
        <linearGradient id="linearGradient1313">
          <stop
            style="stop-color: #e9f3ff; stop-opacity: 1"
            offset="0"
            id="stop1309"
          />
          <stop
            style="stop-color: #b5d6ff; stop-opacity: 0.31"
            offset="1"
            id="stop1311"
          />
        </linearGradient>
        <linearGradient
          id="linear-gradient"
          x1="259.79001"
          y1="160.96001"
          x2="334.12"
          y2="35.950001"
          gradientTransform="matrix(1,0,0,-1,0,352)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.01" id="stop2" />
          <stop offset="0.13" stop-opacity="0.69" id="stop4" />
          <stop offset="0.25" stop-opacity="0.32" id="stop6" />
          <stop offset="1" stop-opacity="0" id="stop8" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="218.97"
          y1="115.81"
          x2="201.7"
          y2="57.25"
          gradientTransform="matrix(1,0,0,-1,0,352)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#fff" id="stop11" />
          <stop offset="1" stop-color="#fff" id="stop13" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-3"
          x1="278.14001"
          y1="201.96001"
          x2="267.72"
          y2="169.86"
        />
        <linearGradient
          id="linear-gradient-4"
          x1="354.54999"
          y1="213.92999"
          x2="330.34"
          y2="167.75"
        />
        <linearGradient
          id="linear-gradient-5"
          x1="265.51001"
          y1="205.85001"
          x2="302.29999"
          y2="148.03999"
        />
        <linearGradient
          id="linear-gradient-6"
          x1="219.3"
          y1="247.81"
          x2="289.88"
          y2="84.510002"
        />
        <linearGradient
          id="linear-gradient-7"
          x1="297.20999"
          y1="281.48001"
          x2="367.78"
          y2="118.18"
        />
        <linearGradient
          id="linear-gradient-8"
          x1="264.14001"
          y1="267.20001"
          x2="334.72"
          y2="103.89"
        />
        <style id="style901">
          .cls-bg121 {
            fill: #f8f8ff;
          }

          .cls-bg122 {
            fill: #fff;
          }

          .cls-bg123 {
            fill: none;
            stroke: #bac6f2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 5px;
          }
        </style>
        <linearGradient
          id="linearGradient1315"
          x1="202.58978"
          y1="141.79167"
          x2="379.11221"
          y2="141.92345"
          gradientUnits="userSpaceOnUse"
        />
        <style id="style901-1">
          .cls-bg121 {
            fill: #f8f8ff;
          }

          .cls-bg122 {
            fill: #fff;
          }

          .cls-bg123 {
            fill: none;
            stroke: #bac6f2;
            stroke-linecap: round;
            stroke-linejoin: round;
            stroke-width: 5px;
          }
        </style>
      </defs>
      <g
        id="g1205"
        transform="matrix(1.1066577,0,0,1.0111371,-460.83644,125.32938)"
      >
        <path
          id="path1119-3"
          style="
            opacity: 1;
            isolation: isolate;
            fill: #e9f3ff;
            fill-opacity: 1;
            stroke-width: 0.77113032;
          "
          d="m 732.5423,84.690814 a 41.805878,37.214264 0 0 1 -1.63463,6.715299 c -4.20103,11.888197 -14.71175,22.212147 -27.09414,28.621877 -14.3276,7.44285 -26.38306,10.57859 -38.74092,10.57859 a 79.459775,70.732568 0 0 1 -18.62671,-2.18265 c -0.81732,-0.16734 -1.63464,-0.3565 -2.45196,-0.54566 q -3.08947,-0.72756 -6.3097,-1.61517 l -2.68082,-0.74938 -1.32404,-0.39288 c -1.77359,-0.5311 -3.57169,-1.09132 -5.41884,-1.68791 l -2.76254,-0.82941 c -9.03954,-3.00478 -17.74399,-7.19549 -26.62825,-10.62225 -8.17319,-3.15757 -16.55073,-5.66763 -25.44316,-5.98776 -22.40271,-0.80758 -42.36984,12.44114 -64.47014,15.83881 -26.53836,4.07429 -54.7604,-7.45741 -70.23225,-27.094022 q -1.15242,-1.4551 -2.19859,-2.968407 c -0.2125,-0.298293 -0.41683,-0.60387 -0.62116,-0.902162 -0.5476,-0.807582 -1.07069,-1.615165 -1.56925,-2.437299 -0.22885,-0.371052 -0.44953,-0.727553 -0.6702,-1.12771 -0.22068,-0.400149 -0.47405,-0.822135 -0.7029,-1.236836 l -0.0572,-0.101856 c -0.23702,-0.414709 -0.45771,-0.82941 -0.67838,-1.251395 v 0 a 80.514117,71.671109 0 0 1 -4.90391,-12.17921 88.033453,78.364584 0 0 1 2.53368,-46.51965 q 0.81733,-2.000767 1.78993,-3.950605 c 3.15486,-6.446108 7.46213,-12.4775102 13.40404,-17.0683613 11.99824,-9.2835581 28.76147,-11.5098658 43.17898,-17.4612387 a 97.596084,86.876933 0 0 0 19.46853,-10.913274 c 21.00511,-14.820226 37.59671,-35.650032 61.29895,-47.239929 a 82.483856,73.424508 0 0 1 32.80721,-8.657857 93.174387,82.940879 0 0 1 55.26713,11.349798 c 10.8213,5.769484 20.92336,12.426583 29.66051,30.222497 4.83036,9.836498 9.2357,23.070661 13.12614,41.4704418 a 70.150509,62.445754 0 0 0 1.7409,6.3151482 c 5.17362,15.351338 16.56706,29.938748 24.11092,44.991791 3.83322,7.653843 6.66115,15.43137 7.16789,23.485366 a 37.809187,33.656537 0 0 1 -0.33511,6.133264 z"
        />
      </g>
      <path
        d="m 192.74494,279.85975 c -7.26,-0.11 -14.36,-2.12 -21.53,-3.6 h 2.66 a 71.44,71.44 0 0 1 18.87,3.6 z"
        style="opacity: 0.49000005; isolation: isolate; fill: #ffffff"
        id="path46"
      />
      <g id="g1042" transform="translate(-993.07096,149.94512)" />
      <path
        style="
          opacity: 1;
          isolation: isolate;
          fill: #f6faff;
          fill-opacity: 1;
          stroke-width: 1.0967952;
        "
        d="m 110.37313,95.195245 c -1.13926,0.896138 -2.2912,1.631233 -3.46836,2.526135 -8.672837,6.18988 -15.343567,9.79215 -26.16579,14.54312 -0.601724,0.27903 -0.621718,0.19305 -1.229244,0.46171 -0.750584,2.66895 -1.411096,7.99748 -1.345034,10.76917 0.0039,2.27842 0.292419,4.54743 0.85902,6.75427 h -0.85902 c -12.726653,0.32849 -22.872774,10.73986 -22.872774,23.47075 0,12.73087 10.146121,23.14227 22.872774,23.47076 h 81.452038 v -0.11992 c 8.64458,-1.17836 15.08598,-8.56717 15.07452,-17.29167 -0.009,-9.60565 -7.78886,-17.39288 -17.39449,-17.41161 -0.93869,0.009 -1.87534,0.0896 -2.80197,0.23992 -4.07838,-7.59959 -12.00011,-12.34619 -20.6249,-12.35819 h -0.84187 c 0.55241,-2.20915 0.8351,-4.4771 0.84187,-6.75427 0.32932,-13.91655 -9.75486,-26.077684 -23.49677,-28.300175 z"
        id="path26-0"
      />
      <g
        id="g1093"
        transform="matrix(-1,0,0,1,-90.384201,-13.437672)"
        style="fill: #edeef3; fill-opacity: 1"
      >
        <ellipse
          id="ellipse947"
          ry="11.52"
          rx="181.93059"
          cy="326.91779"
          cx="-272.31479"
          style="
            opacity: 0.803;
            fill: #edeef3;
            fill-opacity: 1;
            stroke-width: 1.01563752;
          "
        />
        <path
          id="path1031"
          d="m -390.22453,327.13216 c 0,0 8.92,-12.82 -4.39,-16.36 -13.31,-3.54 3.21,-20.81 -9.17,-23.08 -12.38,-2.27 -10.32,16 -10.32,16 0,0 -8.55,-7.37 -14.45,-1.18 -5.9,6.19 2.36,13 2.36,13 0,0 -11.91,4.1 -4.47,11.62 z"
          style="fill: #e0e3eb; fill-opacity: 1"
        />
        <path
          id="path1033"
          d="m -408.11453,328.84216 c 0,0 5.67,-9.69 14,-8.5 8.33,1.19 7.45,8.5 7.45,8.5 z"
          style="fill: #c9cdd9; fill-opacity: 1"
        />
        <polygon
          id="polygon1035"
          points="326.59,241.37 339.28,227.32 334.6,244.11 345.96,236.23 339.06,251.47 318.23,251.47 323.93,227.32 "
          style="fill: #e0e3eb; fill-opacity: 1"
          transform="translate(-441.02655,75.740575)"
        />
      </g>
      <g id="g1278" transform="translate(-221.50164,543.88754)">
        <g
          transform="matrix(0.51878912,0,0,0.51878912,-34.252197,-16.49384)"
          id="g952-5"
          style="
            color: #212529;
            font-style: normal;
            font-variant: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            font-family: Averta, 'Helvetica Neue', 'Open Sans', Arial,
              sans-serif;
            font-variant-ligatures: normal;
            font-variant-caps: normal;
            font-variant-numeric: normal;
            text-indent: 0px;
            text-align: center;
            text-decoration: none;
            text-decoration-line: none;
            text-decoration-style: solid;
            text-decoration-color: #212529;
            letter-spacing: normal;
            word-spacing: 0px;
            text-transform: none;
            writing-mode: lr-tb;
            direction: ltr;
            dominant-baseline: auto;
            baseline-shift: 0px;
            text-anchor: start;
            white-space: normal;
            clip-rule: nonzero;
            display: inline;
            visibility: visible;
            opacity: 1;
            isolation: auto;
            mix-blend-mode: normal;
            vector-effect: none;
            fill: #000000;
            fill-opacity: 1;
            fill-rule: nonzero;
            stroke: none;
            stroke-width: 1px;
            stroke-linecap: butt;
            stroke-linejoin: miter;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-dashoffset: 0px;
            stroke-opacity: 1;
            marker-start: none;
            marker-mid: none;
            marker-end: none;
            paint-order: normal;
            color-rendering: auto;
            image-rendering: auto;
            shape-rendering: auto;
            text-rendering: auto;
          "
        >
          <g id="g1212-2">
            <path
              id="circle921-0"
              d="m 1069.9763,-658.66881 c -18.7774,1.7e-4 -33.9995,15.22225 -33.9997,33.99971 2e-4,18.77744 13.4495,26.80405 32.2269,26.80422 22.8979,-0.0264 25.6501,-0.0337 42.6078,-0.24769 13.2552,2.3e-4 23.1665,-7.30397 23.1662,-20.55916 0,-13.25371 -10.7467,-23.99692 -24.0004,-23.99667 -3.4257,0.01 -6.8095,0.75328 -9.924,2.17981 -5.8734,-11.17346 -17.4537,-18.17322 -30.0768,-18.18012 z"
              clip-path="none"
              style="
                color: #212529;
                font-style: normal;
                font-variant: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                font-family: Averta, 'Helvetica Neue', 'Open Sans', Arial,
                  sans-serif;
                font-variant-ligatures: normal;
                font-variant-caps: normal;
                font-variant-numeric: normal;
                text-indent: 0px;
                text-align: center;
                text-decoration: none;
                text-decoration-line: none;
                text-decoration-style: solid;
                text-decoration-color: #212529;
                letter-spacing: normal;
                word-spacing: 0px;
                text-transform: none;
                writing-mode: lr-tb;
                direction: ltr;
                dominant-baseline: auto;
                baseline-shift: 0px;
                text-anchor: start;
                white-space: normal;
                clip-rule: nonzero;
                display: inline;
                visibility: visible;
                opacity: 0.7;
                isolation: auto;
                mix-blend-mode: normal;
                vector-effect: none;
                fill: #ffffff;
                fill-opacity: 1;
                fill-rule: nonzero;
                stroke: none;
                stroke-width: 1px;
                stroke-linecap: butt;
                stroke-linejoin: miter;
                stroke-miterlimit: 4;
                stroke-dasharray: none;
                stroke-dashoffset: 0px;
                stroke-opacity: 1;
                marker-start: none;
                marker-mid: none;
                marker-end: none;
                paint-order: normal;
                color-rendering: auto;
                image-rendering: auto;
                shape-rendering: auto;
                text-rendering: auto;
              "
            />
          </g>
          <g id="g1215-0" />
        </g>
        <g transform="translate(-984.7459,128.65537)" id="g1042-5" />
      </g>
      <g
        style="fill: none"
        id="g935"
        transform="translate(-19.215209,150.26144)"
      >
        <g id="g899" transform="matrix(-1,0,0,1,153.57055,0.91811442)">
          <path
            id="path851"
            d="m 76.0299,91.744119 1.0998,-0.234966 c 1.2148,-0.259532 2.4334,0.27844 2.7219,1.201486 l 20.6304,66.020141 c 0.289,0.92297 0.084,1.76534 -0.457,1.88081 l -0.4896,0.10462 c -0.541,0.11546 -1.2463,-0.5324 -1.5754,-1.44607 L 74.426,93.869813 c -0.329,-0.914444 0.389,-1.866085 1.6039,-2.125694 z"
            style="
              clip-rule: evenodd;
              fill: #3e48b4;
              fill-opacity: 1;
              fill-rule: evenodd;
              stroke-width: 0.88031387;
            "
          />
          <path
            id="path853"
            d="m 75.7101,93.015351 1.0998,0.234965 c 1.2148,0.259609 1.9329,1.211251 1.6038,2.125695 l -23.534,65.399869 c -0.329,0.91444 -1.0343,1.5623 -1.5753,1.44683 l -0.4898,-0.10462 c -0.541,-0.11624 -0.7457,-0.95784 -0.4573,-1.88081 L 72.9882,94.216837 c 0.2884,-0.923046 1.5071,-1.461018 2.7219,-1.201486 z"
            style="
              clip-rule: evenodd;
              fill: #4668d9;
              fill-opacity: 1;
              fill-rule: evenodd;
              stroke-width: 0.88031387;
            "
          />
          <path
            id="path857"
            d="m 75.9023,85.6769 7.2634,4.1935 -0.6197,1.0734 c -0.5523,0.9565 -1.7754,1.2843 -2.732,0.732 l -3.7993,-2.1935 c -0.9566,-0.5523 -1.2843,-1.7755 -0.732,-2.732 z"
            style="
              clip-rule: evenodd;
              fill: #a6acbf;
              fill-opacity: 1;
              fill-rule: evenodd;
            "
          />
          <path
            id="path859"
            d="m 76.4664,99.2004 c 2.6981,0 4.8853,-2.1715 4.8853,-4.8501 0,-2.6786 -2.1872,-4.85 -4.8853,-4.85 -2.6981,0 -4.8853,2.1714 -4.8853,4.85 0,2.6786 2.1872,4.8501 4.8853,4.8501 z"
            style="clip-rule: evenodd; fill: #2a4b8d; fill-rule: evenodd"
          />
          <path
            id="path867"
            d="m 76.4664,99.2004 c 2.6981,0 4.8853,-2.1715 4.8853,-4.8501 0,-2.6786 -2.1872,-4.85 -4.8853,-4.85 -2.6981,0 -4.8853,2.1714 -4.8853,4.85 0,2.6786 2.1872,4.8501 4.8853,4.8501 z"
            style="
              clip-rule: evenodd;
              fill: #4c88ff;
              fill-opacity: 1;
              fill-rule: evenodd;
            "
          />
          <path
            id="path869"
            d="m 76.2553,96.249 c 1.1564,0 2.0937,-0.9306 2.0937,-2.0786 0,-1.148 -0.9373,-2.0786 -2.0937,-2.0786 -1.1563,0 -2.0937,0.9306 -2.0937,2.0786 0,1.148 0.9374,2.0786 2.0937,2.0786 z"
            style="clip-rule: evenodd; fill: #eaf3ff; fill-rule: evenodd"
          />
          <path
            id="path871"
            d="m 118.301,100.498 9.279,5.357 c 0.957,0.553 1.285,1.776 0.732,2.732 l -0.623,1.081 c -0.553,0.957 -1.776,1.284 -2.733,0.732 l -9.279,-5.357 z"
            style="
              clip-rule: evenodd;
              fill: #e0e3eb;
              fill-opacity: 1;
              fill-rule: evenodd;
            "
          />
          <path
            id="path875"
            d="m 53.5659,57.737 29.78,17.1935 28.0481,16.1935 c 0.956,0.5522 1.284,1.7754 0.732,2.732 l -4.997,8.655 c -0.552,0.956 -1.775,1.284 -2.732,0.732 L 46.569,69.856 Z"
            style="
              clip-rule: evenodd;
              fill: #e0e3eb;
              fill-opacity: 1;
              fill-rule: evenodd;
            "
          />
          <rect
            id="rect877"
            transform="rotate(30,32.2578,41.4742)"
            rx="2"
            height="21.505199"
            width="22.539301"
            y="41.474201"
            x="32.257801"
            style="fill: #e0e3eb; fill-opacity: 1"
          />
          <path
            id="path883"
            d="M 55.0288,63.2945 111.085,95.6587 109.628,98.1834 53.5711,65.8193 Z"
            style="
              clip-rule: evenodd;
              opacity: 0.46700003;
              fill: #c9cdd9;
              fill-opacity: 1;
              fill-rule: evenodd;
            "
          />
          <path
            id="path885"
            d="M 29.6685,45.9594 49.1881,57.2291 46.5643,61.7737 27.0446,50.5041 Z"
            style="
              clip-rule: evenodd;
              opacity: 0.46700003;
              fill: #c9cdd9;
              fill-opacity: 1;
              fill-rule: evenodd;
            "
          />
          <g id="g1286" style="fill: #4c88ff; fill-opacity: 1">
            <path
              style="
                clip-rule: evenodd;
                fill: #4c88ff;
                fill-opacity: 1;
                fill-rule: evenodd;
                stroke-width: 1.10572302;
              "
              d="m 48.877229,51.221692 4.677612,2.756626 c 1.046937,0.616878 1.405583,1.983349 0.801127,3.051993 l -8.340356,14.745531 c -0.604457,1.068645 -1.943169,1.434727 -2.990105,0.817849 l -4.677613,-2.756624 z"
              id="path873"
            />
            <path
              style="
                clip-rule: evenodd;
                fill: #4668d9;
                fill-opacity: 1;
                fill-rule: evenodd;
              "
              d="M 53.5659,57.737 57.0694,59.7598 50.0726,71.8787 46.569,69.856 Z"
              id="path879"
            />
            <path
              style="
                clip-rule: evenodd;
                fill: #4668d9;
                fill-opacity: 1;
                fill-rule: evenodd;
              "
              d="m 111.96,94.1438 9.279,5.3573 c 0.957,0.5519 1.284,1.7759 0.732,2.7319 l -2.665,4.615 c -0.552,0.957 -1.775,1.285 -2.732,0.732 l -9.279,-5.357 z"
              id="path881"
            />
            <path
              style="
                clip-rule: evenodd;
                fill: #4c88ff;
                fill-opacity: 1;
                fill-rule: evenodd;
              "
              d="m 71.9506,68.6612 c 0.7182,-1.2439 2.3088,-1.6701 3.5526,-0.9519 1.2439,0.7181 1.6701,2.3087 0.952,3.5526 l -7.0201,12.159 c -0.7181,1.2439 -2.3087,1.6701 -3.5526,0.9519 -1.2439,-0.7181 -1.6701,-2.3087 -0.9519,-3.5526 z"
              id="path887"
            />
            <path
              style="
                clip-rule: evenodd;
                fill: #4c88ff;
                fill-opacity: 1;
                fill-rule: evenodd;
              "
              d="m 92.4711,80.5088 c 0.7182,-1.2439 2.3088,-1.6701 3.5527,-0.9519 1.2438,0.7181 1.67,2.3087 0.9519,3.5526 l -7.0201,12.159 c -0.7181,1.2439 -2.3087,1.6701 -3.5526,0.952 -1.2439,-0.7182 -1.6701,-2.3088 -0.9519,-3.5526 z"
              id="path889"
            />
            <path
              style="
                clip-rule: evenodd;
                fill: #4c88ff;
                fill-opacity: 1;
                fill-rule: evenodd;
              "
              d="m 31.2578,43.2063 c 0.5523,-0.9566 1.7755,-1.2843 2.7321,-0.7321 l 2.1779,1.2575 -10.7526,18.624 -2.1779,-1.2574 C 22.2807,60.546 21.953,59.3228 22.5052,58.3662 Z"
              id="path891"
            />
          </g>
        </g>
      </g>
      <circle
        style="
          opacity: 1;
          fill: #4c88ff;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 6.5813179;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        id="path1313"
        cx="55.327396"
        cy="88.136314"
        r="1.8042744"
      />
      <circle
        style="
          opacity: 1;
          fill: #4c88ff;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 6.00316954;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        id="path1313-6"
        cx="35.15028"
        cy="153.29776"
        r="1.6457746"
      />
      <circle
        style="
          opacity: 1;
          fill: #4c88ff;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 4.02978897;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        id="path1313-0-9"
        cx="310.22073"
        cy="154.14738"
        r="1.1047704"
      />
      <circle
        style="
          opacity: 1;
          fill: #4c88ff;
          fill-opacity: 1;
          stroke: none;
          stroke-width: 5.92723894;
          stroke-miterlimit: 4;
          stroke-dasharray: none;
          stroke-opacity: 1;
        "
        id="path1313-0-9-1"
        cx="286.70786"
        cy="130.2049"
        r="1.624958"
      />
      <g id="g1446" transform="rotate(22.389313,189.31222,-92.307765)">
        <circle
          r="1.7539754"
          cy="30.499464"
          cx="286.47327"
          id="path1313-0"
          style="
            opacity: 1;
            fill: #4c88ff;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 6.39784575;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
        />
        <circle
          r="1.6457745"
          cy="77.203949"
          cx="254.57074"
          id="path1313-6-3-6"
          style="
            opacity: 1;
            fill: #4c88ff;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 6.00316954;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
        />
        <circle
          r="1.0336982"
          cy="73.726479"
          cx="260.68243"
          id="path1313-6-3-6-9"
          style="
            opacity: 1;
            fill: #4c88ff;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 3.77054429;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
        />
        <circle
          r="1.1828724"
          cy="88.106422"
          cx="219.66951"
          id="path1313-6-3-6-9-2"
          style="
            opacity: 1;
            fill: #4c88ff;
            fill-opacity: 1;
            stroke: none;
            stroke-width: 4.31467628;
            stroke-miterlimit: 4;
            stroke-dasharray: none;
            stroke-opacity: 1;
          "
        />
      </g>
    </svg>
    <div class="empty-state__icon">
      <!-- doesnt use wr-icon because we need a size that doesnt exist -->
      <i class="fa-regular fa-calendar-days"></i>
    </div>
  </div>

  <div class="empty-state__content" *ngIf="!error || error?.status === 404">
    <div class="empty-state__title">
      {{ "Calendar.blkNotFound.title" | translate }}
    </div>
  
    <div class="empty-state__content">
      <span> {{ "Calendar.blkNotFound.subtitle" | translate }}</span>
    </div>
  </div>
  
  <div class="empty-state__content" *ngIf="error?.status && error?.status !== 404" style="color: #ab093f; background-color: #ffe3e9; border-radius: .8rem; padding: 1.6rem; margin: .8rem; font-size: 1.6rem;">
    {{ getErrorMessage() }}
  </div>

  <div *ngIf="error?.path" class="empty-state__content" style="margin-top: 3rem;">
    <span>{{ "global.lblErrorPath" | translate }} {{ error.path }}</span>
  </div>
</div>
